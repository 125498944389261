<template>
  <v-card class="elevation-0">

    <v-card-title>
      BERITA-AGENDA
      <v-spacer></v-spacer>

        <v-text-field
            v-on:keyup.enter="searchOnEnter"
            v-on:blur="searchOnEnter"
            append-icon="mdi-magnify"
            hint="Kode, Deskripsi (press ENTER to search)"
            label="Search"
        ></v-text-field>

    </v-card-title>

<!--    :search="search"-->
    <v-data-table
        v-model="selectedItems"
        :single-select="!multiSelect"
        :show-select="multiSelect"
        :headers="headers"
        :items="fKegiatansFiltered"
        :page.sync="currentPage"
        :items-per-page="pageSize"
        hide-default-footer
        class="elevation-0"
        @page-count="totalTablePages =totalPaginationPages "
        align="start"
    >
      <template v-slot:top>
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
              v-model="multiSelect"
              :label="multiSelect?'Multi Select':'Single Select'"
              x-small
              class="pa-3"
          ></v-switch>
          <v-btn
              fab
              dark
              color="red accent-4"
              x-small
              :disabled="!multiSelect"
              class="mr-2"
              @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
              fab
              dark
              color="green"
              x-small
              @click="showDialogNew"
              :disabled="multiSelect"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.title`]="{ item }">
        <div class="fill-height">
          <div>
            {{item.title}}
            <div class="mt-2">
              <v-chip small :color="`${item.typeNews===1? 'blue':'green'}`" class="white--text">
                {{ lookupTypeNews(item.typeNews) }}
              </v-chip>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:[`item.contentMeta`]="{ item }">
        <div class="fill-height">
          {{item.contentMeta}}
        </div>
      </template>
      <template v-slot:[`item.created`]="{ item }">
        <div class="fill-height">
          <div>
            {{ dateTimeFormat(item.created ) }}
          </div>
          <div>
            by {{item.modifiedBy}}
          </div>
          <div>
            <div class="green--text" v-if="item.flagExposed">
              <v-icon color="green">
                mdi-checkbox-blank-circle
              </v-icon>
              Sedang Tayang
            </div>
            <div v-else>
              <v-icon color="grey" small>
                mdi-checkbox-blank-circle
              </v-icon>
            </div>
          </div>

        </div>
      </template>

      <template v-slot:[`item.fdivisionBean`]="{ item }">
        {{ lookupFDivision(item.fdivisionBean) }}
      </template>
      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip
            :color="getColorStatusActive(item.statusActive)"
            dark
            small
        >
          {{ item.statusActive==true?'Aktif': '-' }}
        </v-chip>
      </template>

      <template v-slot:[`item.number`]="{ index }">
        {{ (index + ((currentPage-1)*pageSize) +1 )}}
      </template>

      <template v-slot:[`item.coverImage`]="{item}">
        <v-img
          :src="lookupImageUrl(item)"
          width="150px"
          height="150px"
          class="ma-2 rounded"
        >
        </v-img>
      </template>


      <template v-slot:[`item.cvptBean`]="{ item }">
        <div class="fill-height">
          <div>
            {{ lookupFCvpt(item.cvptBean) }}
          </div>
          <div v-if="item.nomorKontrak" class="mt-2">
            <div>
              *{{ item.nomorKontrak }}
            </div>
          </div>
          <v-spacer></v-spacer>
          <div></div>
        </div>
      </template>


      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            @click="showDialogEdit(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="warning"
          >
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            @click="deleteDialogShow(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="red accent-4"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>
<!--    @page-count="pageCount = $event"-->

    <v-container>
      <v-row justify="end" align="center">
        <v-col
        cols="4"
        md="2"
        sm="2"
        >
          <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Items per page"
          ></v-select>
        </v-col>
        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
              total-visible="8"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <delete-dialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
        @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></delete-dialog>

    <FBeritaAgendaDialog :formMode="formMode"
                  ref="refFormDialog"
                  @eventFromFormDialogNew="saveDataNew"
                  @eventFromFormDialogEdit="saveDataEdit"
    ></FBeritaAgendaDialog>

    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>
import FNewsService from '@/services/apiservices/f-news-service';
import FDivisionService from "@/services/apiservices/f-division-service";
import FileService from "@/services/apiservices/file-service"

import DeleteDialog from "@/components/utils/DeleteConfirmDialog";
import FBeritaAgendaDialog from "./FBeritaAgendaDialog";
import FormMode from "@/models/form-mode";
import FNews from '@/models/f-news'
import FDivision from '@/models/f-division'

import {format, parseISO} from "date-fns";

export default {
  components: { FBeritaAgendaDialog, DeleteDialog},
  data () {
    return {
      snackbar: false,
      snackBarMesage: '',

      multiSelect: false,
      selectedItems: [],
      imageBrokenLink: './assets/img_sample/sample-1.jpeg',

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500],

      search: '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'coverImage',
          width: '20%'
        },
        { text: 'Judul', value: 'title', width:'30%' },
        { text: 'Deskripsi Singkat', value: 'contentMeta', width: '25%' },
        { text: 'Tanggal', value: 'created', align:'start', width: '30%' },
        { text: 'Actions', value: 'actions', align:'start', sortable: false },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      fKegiatans:[
          new FNews(1, "001")
      ],
      itemsFDivision: [
          new FDivision()
      ],
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFNews()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage==1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)
          this.fetchFNews()
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    fKegiatansFiltered(){
      return this.fKegiatans
    }
  },
  methods: {

    searchOnEnter(event){
      if (this.search !== event.target.value) { //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1
        this.search = event.target.value
        this.fetchFNews()
      }
    },
    fetchParent(){
      if (this.currentUser.organizationLevel === "CORP") {
        FDivisionService.getAllFDivision().then(
            response => {
              // console.log(response.data)
              this.itemsFDivision = response.data
            },
            error => {
              console.log(error.response)
            }
        )
      }else  if (this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
            response=>{
              this.itemsFDivision = [response.data]
              // console.log(response.data.items)
            },
            error=>{
              console.log(error.response)
            }
        )
      }


    },
    fetchFNews() {

      FNewsService.getAllFNewsContaining(this.currentPage, this.pageSize, "created", "DESC", this.search).then(
          response => {
            // console.log(response.data.items)
            const { items, totalPages} = response.data
            this.fKegiatans = items
            this.totalPaginationPages = totalPages

            // console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
          },
          error => {
            console.log(error.response)
            if (error.response.status ==401){
              this.snackBarMesage = "Anda tidak mempunyai Akses!!"
              this.snackbar = true
            }
          }
      )

    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },

    showDialogNew() {
      this.itemSelectedIndex =-1
      const itemModified = Object.assign({}, '')
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision, this.itemsFCvpt, this.itemsFPegawai)
    },
    saveDataNew(itemFromRest){
      this.itemSelected = itemFromRest
      this.closeDialog()
      /**
       * Setiap data baru harus terlihat
       */
      // this.fBeritas.push(this.itemSelected)
      // this.fBeritas = [itemFromRest].concat(this.fBeritas)
      // this.fBeritas.unshift(itemFromRest)
      this.fetchFNews()
    },

    showDialogEdit (item) {
      this.itemSelectedIndex = this.fKegiatansFiltered.indexOf(item)
      const itemModified = Object.assign({}, item)

      this.formMode = FormMode.EDIT_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision)
    },
    saveDataEdit(item){
        this.itemSelected = item
        try {
          //Kalau Create terus uplaod image duluan tidak bisa
          Object.assign(this.fKegiatans[this.itemSelectedIndex], this.itemSelected)
        }catch (e){console.log(e)}
      this.closeDialog()

    },


    deleteDialogShow (item) {
      // console.log(item)
      this.itemSelectedIndex = this.fKegiatansFiltered.indexOf(item)
      this.itemSelected = Object.assign({}, item)
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, item.username)
    },
    deleteDialogMultiShow(){
      if (this.multiSelect===true){
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(this.selectedItems, `${this.selectedItems.length} items selected`)
      }
    },
    deleteItemConfirmedSingleSelect(index){
      console.log("delete SingleItem: " + index)

      const deletedItem = this.fKegiatans[this.itemSelectedIndex]
      FNewsService.deleteFNews(deletedItem.id).then(
        () => {
          // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
          this.fKegiatans.splice(this.itemSelectedIndex, 1)
          this.closeDialog()
        },
        error => {
          console.log(error)
          this.snackBarMesage = 'gagal hapus (digunakan oleh data anakk)'
          this.snackbar = true
          this.$refs.refDeleteConfirmDialog.setDialogState(false)
        }
      )
    },
    deleteItemConfirmedMultiSelect(items){
      // console.log(okeMessage)
      if (items.length >-1){
        let itemIds = []
        for (let i=0; i<items.length; i++){
            itemIds.push(items[i].id)
        }
        // console.log("Item idex: " + itemIds)

        FNewsService.deleteAllFNews(itemIds).then(
            response => {
              if (response.data) {
                  for (let i = 0; i < items.length; i++) {
                    if (! response.data.includes(items[i].id)  || response.data.length === 0) {
                      const index = this.fKegiatansFiltered.indexOf(items[i])
                      this.fKegiatans.splice(index, 1)
                    }
                    if (response.data.length > 0) {
                      this.snackBarMesage = `${response.data.length} items gagal hapus (digunakan oleh data anak)`
                      this.snackbar = true
                    }

                    // console.log("Undeleted Items: " + response.data + " = " + items[i].id)

                }
                this.closeDialog()
              }
            },
            error => {
              console.log("error " + error)
            }
        )

        // console.log(items)
        // FNewsService.deleteAllFNewss()
      }

    },
    closeDialog () {
      // this.myConfirmDialog = false
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FNews())
        this.itemSelectedIndex = -1
      })
    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id==fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },
    lookupFCvpt (cvptBean) {
      const str = this.itemsFCvpt.filter(x => x.id==cvptBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },
    lookupTypeNews (typeNewsBean) {
       if (typeNewsBean===1){
         return "Berita"
       }else  if (typeNewsBean===2){
         return "Agenda"
       }
    },

    lookupImageUrl(item){
      if (item.coverImage===undefined || item.title===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.coverImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/no_image_available.jpeg'
    },

    onImgError: function(event) {
      /**
       * masih belum fungsi
       */
      console.log(event)
    },
    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },
    dateTimeFormat (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy hh:mm:ss') : ''
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      // return "Rp." + parseFloat(value).toFixed(0).toLocaleString()
      return prefix + "" + parseFloat(value).toLocaleString()
    },

  },
  mounted() {
    // if (!this.currentUser) {
    //   this.$router.push('/login')
    // }else {
    //   // this.fetchFNews()
    //   this.fetchParent()
    //
    // }

    this.fetchFNews()
    this.fetchParent()

  }

}
</script>

<style scoped>

</style>