<template>

  <div class="mt-4">
    <v-row>
      <v-col
        cols="12"
        md="5"
        sm="5"
      >
        <v-container class="pa-4 text-center">
          <v-row
              class="fill-height"
              align="center"
              justify="center"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                  class="align-self-center"
                  :elevation="hover ? 10 : 1"
                  :class="[{ 'on-hover': hover }, hover?'card-hover-opacity':'card-not-hover-opacity']"
              >
                <v-img
                    :lazy-src="lookupImageUrlLazy(itemModified)"
                    :src="lookupImageUrl(itemModified)"
                    max-height="250x"
                    max-width="300px"
                >
                  <v-card-title class="text-h6 white--text fill-height">

                    <v-row
                        class="fill-height flex-column"
                        justify="space-between"
                    >
                      <v-spacer/>
                      <div class="align-self-center">
                        <v-btn
                            :class="{ 'show-btns': hover }"
                            :color="transparent"
                            icon
                            large
                            dark
                            outlined
                            @click="showDialogUpload"
                        >
                          <v-icon
                              :class="{ 'show-btns': hover }"
                              :color="transparent"
                              large
                          >
                            mdi-upload
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-row>

                  </v-card-title>
                </v-img>
              </v-card>
            </v-hover>

          </v-row>
        </v-container>

      </v-col>
      <v-col
          cols="12"
          md="7"
          sm="7"
          class="mt-4"
      >
        <v-row>
          <v-text-field
              v-model="itemModified.title"
              :rules="rulesNotEmtpy"
              label="Judul"
          ></v-text-field>
        </v-row>
        <v-row>
          <v-text-field
              v-model="itemModified.contentMeta"
              :rules="rulesNotEmtpy"
              label="Deskripsi Singkat"
          ></v-text-field>
        </v-row>

        <v-row>
          <v-col>
            <v-autocomplete
                v-model="itemModified.typeNews"
                :items="itemsTypeNews"
                :rules="rulesNotEmtpy"
                item-value="id"
                item-text="description"
                auto-select-first
                dense
                chips
                deletable-chips
                color="blue-grey lighten-2"
                label="Tipe Berita"
                hint="1=Berita, 2=Agenda"
                persistent-hint
            ></v-autocomplete>
          </v-col>
          <v-col>
              <v-switch
                  :disabled="! isRedaktur"
                  v-model="itemModified.flagExposed"
                  :label="itemModified.flagExposed?'Tayang':'Tidak Tayang'"
                  x-small
              ></v-switch>
          </v-col>

        </v-row>

        <v-row class="mt-4">
          <v-autocomplete
              v-model="itemModified.fdivisionBean"
              :items="itemsFDivision"
              :rules="rulesNotEmtpy"
              item-value="id"
              item-text="description"
              auto-select-first
              dense
              chips
              deletable-chips
              color="blue-grey lighten-2"
              label="Bidang"
              persistent-hint
          ></v-autocomplete>
        </v-row>

      </v-col>

    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="12"
        sm="12"
      >
<!--        <v-textarea-->
<!--            v-model="itemModified.contentBody"-->
<!--            label="Isi Berita"-->
<!--            counter-->
<!--            outlined-->
<!--            auto-grow-->
<!--        >-->
<!--        </v-textarea>-->

        <vue-editor v-model="itemModified.contentBody" />

      </v-col>
    </v-row>

    <upload-image-dialog
      ref="refUploadDialog"
      @eventUploadSuccess="completeUploadSuccess"
    >
    </upload-image-dialog>
    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>

</template>

<script>
import FileService from "@/services/apiservices/file-service";
import UploadImageDialog from "@/components/utils/UploadImageDialog";
import FNews from "@/models/f-news";
import FEnumHelper from "@/models/f-enum-helper";
import ERole from "@/models/e-role";

import { VueEditor } from "vue2-editor";

export default {
  name: "FDaftarKegiatanDokumentasi",
  components:{
    UploadImageDialog,
    VueEditor
  },
  props:{
    itemModified: new FNews(),
    itemsFDivision:[]
  },
  computed:{
    isRedaktur(){
      if ( (this.currentUser.roles.includes(ERole.ROLE_ADMIN) && this.currentUser.organizationLevel === "CORP") ||
          (this.currentUser.roles.includes(ERole.ROLE_MANAGER) && this.currentUser.organizationLevel === "CORP")  ||
          (this.currentUser.roles.includes(ERole.ROLE_PUBLIC) && this.currentUser.organizationLevel === "CORP")  ||
          (this.currentUser.roles.includes(ERole.ROLE_PUBLIC_1) && this.currentUser.organizationLevel === "CORP")
      ){
        return true
      }else {
        return false
      }
    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  data() {
    return{
      snackbar: false,
      snackBarMesage: '',

      transparent: 'rgba(255, 255, 255, 0)',
      itemsTypeNews:[
        new FEnumHelper(1, 'Berita'), new FEnumHelper(2, 'Agenda')
      ],

      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],

    }
  },
  methods: {
    showDialogUpload(){
      if (this.itemModified.title !==undefined && this.itemModified.contentMeta !==undefined &&
          this.itemModified.fdivisionBean !==undefined){

        if (this.itemModified.id===0){
          this.$emit('eventSaveItemWithoutClose', false)
          // console.log("masuk masih kosong")
          // }else {
          //   console.log("Sudah ada isinya")
        }
        this.$refs.refUploadDialog.showDialog()

      }else{
        this.snackBarMesage = 'Deskripsi dan Divisi harus diisi dahulu'
        this.snackbar = true
      }

    },
    completeUploadSuccess: function (val){

      if (val.fileName !==""){
        // console.log(val.fileName)
        // console.log(val.description)

        //Hapus Dulu yang lama
        // console.log(this.itemModified.avatarImage)
        FileService.deleteImage(this.itemModified.coverImage).then(
            response=>{
              console.log(response.data)
            },
            error => {
              console.log(error.response)
            }
        )


        this.itemModified.coverImage = val.fileName
        // console.log(`mau disave ${this.itemModified.coverImage}`)
        this.$refs.refUploadDialog.closeDialog()
        this.$emit("saveUpdateOnly", 'dummy')
      }

    },
    lookupImageUrl(item){
      if (item.coverImage===undefined || item.coverImage===""){
          return './assets/images/image_icon.png'
      }else {
        return FileService.image_url_medium(item.coverImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/image_icon.png'
    },

  }
}
</script>

<style scoped>

.card-hover-opacity{
  opacity: 0.4;
}
.card-not-hover-opacity{
  opacity: 1;
  /*transition: opacity .4s ease-in-out;*/
  transition: opacity .4s ease-in-out;
}

.show-btns {
  color: blue !important;
  opacity: 1;
}

</style>