export default class FEnumHelper {
  constructor(
    id,
    description,
  ) {
    this.id = id;
    this.description = description;
  }

}
