<template>
  <v-card class="mt-2 ml-2 mr-2">
      <FBeritaAgendaTable class="pl-2 pr-2" ref="refBeritaAgendaTable"/>
  </v-card>
</template>

<script>
import FBeritaAgendaTable from "@/components/berita_agenda/FBeritaAgendaTable";

export default {
  name: "BeritaAgendaView",
  components: {
    FBeritaAgendaTable
  },
  data() {
    return {
      tabTitle: 'BeritaAgenda'
    }
  },
  computed: {
  },
  methods: {
    tabBeritaAgendaClick(){
      this.$refs.refBeritaAgendaTable.fetchParent()
    },

  },


}
</script>

<style scoped>
</style>