<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      @keydown.esc.prevent="closeForm"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >

      <v-form v-model="valid"></v-form>
      <div v-if="isItemModified"></div>
      <div v-if="formMode"></div>
      <v-card>
        <v-form v-model="valid" ref="form">

          <v-toolbar
            dark
            color="brown"
            class="color-gradient-1"
            dense
          >
            <v-btn
                icon
                dark
                @click="closeForm"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>BERITA-AGENDA</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items >
              <v-btn
                  dark
                  text
                  @click="save"
                  :disabled="!valid || isItemModified===false"
                  class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>


          <v-card-text>

            <v-container>
              <FBeritaAgendaDoc
                  :itemModified="itemModified"
                  :itemsFDivision="itemsFDivision"
                  @saveUpdateOnly="saveUpdateOnly"
                  @retrieveFiles="retrieveFiles"
              >
              </FBeritaAgendaDoc>

            </v-container>

          </v-card-text>

          <v-card-actions>
            <v-chip
                class="ml-4"
                color="error"
                outlined
                close
                small
                v-show="formDialogOptions.errorMessage"
            >
              {{formDialogOptions.errorMessage}}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="closeForm"
                class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="save"
                :disabled="!valid || isItemModified===false"
                class="hidden-sm-and-down"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-form>

      </v-card>

      <close-confirm-dialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></close-confirm-dialog>

    </v-dialog>

  </div>

</template>

<script>
import FNewsService from '@/services/apiservices/f-news-service';
import FDivision from "@/models/f-division";

import CloseConfirmDialog from "@/components/utils/CloseConfirmDialog";
import FormMode from "@/models/form-mode";
import FNews from "@/models/f-news";
import { format, parseISO } from 'date-fns'
import FBeritaAgendaDoc from "./FBeritaAgendaDoc";

export default {
  name: "UsersDialog",
  components: {
    FBeritaAgendaDoc,
    CloseConfirmDialog
  },
  props:{
    formMode: String
  },
  data() {
    return {
      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },
      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,
      menuDate2: false,
      menuDate3: false,


      selectedItemIndex: -1,
      itemDefault: '',
      itemModified: new FNews(0, '', ''),
      selectFDivision: {id: 0, kode1: '', description: '' },
      itemsFDivision: [
          new FDivision()
      ],
      itemsFile:[
        // new FNewsFile(1, '1','file1.jpg'),
        // new FNewsFile(2, '2','file2.jpg')
      ],

      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],

    }
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault)
      const modifiedItem = JSON.stringify(this.itemModified)
      return defaultItem !== modifiedItem
    },

  },

  watch: {
  },

  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },

    showDialog(selectedIndex, item, itemsFDivision) {
      // console.log("show UserDialog " + item.id)
      // console.log(this.formMode)
      this.dialogShow = !this.dialogShow
      if (selectedIndex >-1) {
        this.selectedIndex = selectedIndex
        this.initializeEditMode(item)

      }else {
        this.itemDefault =  new FNews(0, '', ''),
        this.itemModified =  new FNews(0, '', ''),
        this.selectedIndex = -1


      }

      this.itemsFDivision = itemsFDivision

    },
    setDialogState(value){
      this.dialogShow =value
    },
    save(andCloseDialog){
      console.log(andCloseDialog)
      if (this.isItemModified===false){
        //Close aja
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
        return
      }

      if (this.$refs.form.validate()){
        if (this.formMode===FormMode.EDIT_FORM) {

          // console.log(`cek sebelum update >> ${this.itemModified.id}`)        </v-card-text>
          this.itemModified.modifiedBy = this.$store.state.auth.user.username;
          this.itemModified.modified = new Date();
          
          FNewsService.updateFNews(this.itemModified).then(
              () => {
                // console.log(response.data)
                if (andCloseDialog){
                  this.$emit('eventFromFormDialogEdit', this.itemModified)
                }
              },
              error => {
                // console.log(error);
                this.formDialogOptions.errorMessage = error.response.data.message
              }
          )
        }else {

          if (this.itemModified.fdivisionBean >0 && this.itemModified.title !==undefined &&
              this.itemModified.contentMeta !==undefined){

            FNewsService.createFNews(this.itemModified).then(
                response =>{
                  if (andCloseDialog){
                    this.$emit('eventFromFormDialogNew', response.data)
                  }
                },
                error => {
                  this.formDialogOptions.errorMessage = error.response.data.message
                }
            )

          }

        }//endif
      }//endif validate

    },
    saveCreateOnly(){
      this.itemModified.modifiedBy = this.$store.state.auth.user.username;
      this.itemModified.modified = new Date();

      FNewsService.createFNews(this.itemModified).then(
          response =>{
              /**
               * dipaksa Save dan Update Dahulu
               */
              this.initializeEditMode(response.data)
              this.formMode = FormMode.EDIT_FORM
          },
          error => {
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    saveUpdateOnly(){
      // console.log(`sampek sini ${this.itemModified.coverImage}`)
      FNewsService.updateFNews(this.itemModified).then(
          () => {
          },
          error => {
            // console.log(error);
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    closeForm(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }else {
        this.$refs.refCloseConfirmDialog.showDialog(" Sudah terdapat modifikasi data", "Tetap tutup dan reset perubahan?")
      }
    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },
    initializeEditMode(item){
      // this.itemDefault = item
      // this.itemModified = item

      this.formDialogOptions.errorMessage = ''

      FNewsService.getFNewsById(item.id).then(
          response =>{
            this.itemDefault = Object.assign({}, response.data)
            this.itemModified = response.data


            // this.retrieveFiles()

          },
          error =>{
            console.log(error)
          }
      )

    },
    retrieveFiles(){
      // FNewsFileService.getAllFNewsFileByParent(this.itemModified.id).then(
      //     response=>{
      //       this.itemsFile = response.data
      //     },
      //     error=>{
      //       console.log(error.response)
      //     }
      // )
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      // return "Rp." + parseFloat(value).toFixed(0).toLocaleString()
      return prefix + "" + parseFloat(value).toLocaleString()
    },


  }

}
</script>

<style scoped>
</style>